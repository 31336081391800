import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from '../ApiHttpService/apiHttp.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyorService {

  constructor(private api: ApiHttpService) { }
  private API_URL = environment.PathAPI

  getDashboardCount(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerDashboardCount/${reqData}`
    return this.api.get(url)
  }
  getPendingDashboardCount(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerStatusDashboardCount/${reqData}`
    return this.api.get(url)
  }
  GetInsurerDashboardDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerDashboardDetails/${reqData}`
    return this.api.get(url)
  }
  GetInsurerStatusDashboardDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerStatusDashboardDetails/${reqData}`
    return this.api.get(url)
  }
  GetInsurerOpenClaimDashboard(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerOpenClaimDashboard/${reqData}`
    return this.api.get(url)
  }
  GetInsurerOpenClaimsCount(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerOpenClaimsCount/${reqData}`
    return this.api.get(url)
  }
  GetInsurerSurveyorOfficerList(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerSurveyorOfficerList/${reqData}`
    return this.api.get(url)
  }
  GetClaimSurveyorOfficerDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetClaimSurveyorOfficerDetails/${reqData}`
    return this.api.get(url)
  }

  GetClaimDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetClaimDetails/${reqData}`
    return this.api.get(url)
  }

  GetClaimStamps(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetClaimStamps/${reqData}`
    return this.api.get(url)
  }

  GetInsurerVideoDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerVideoDetails/${reqData}`
    return this.api.get(url)
  }

  GetEstimateDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetEstimateDetails/${reqData}`
    return this.api.get(url)
  }

  UpdateEstimateDetails(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/UpdateEstimateDetails";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  GetCustomerDocs(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetCustomerDocs/" + reqData;
    return this.api.get(url)
  };

  DownloadZIP(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/DownloadZIP/" + reqData;
    return this.api.get(url)
  };

  uploadSurveyorDocs(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/GetUploadedFileUrl";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  GetSurveyorDocs(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetSurveyorDocs/" + reqData;
    return this.api.get(url)
  };


  GetClaimPaymentDetail(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetClaimPaymentDetail/" + reqData;
    return this.api.get(url)
  };

  SetSurveyorDocs(reqData: any): Observable<any> {
    reqData?.SurveyorDocs?.forEach((docItem) => {
      docItem.DocStatusID = docItem.DocStatusID ? !isNaN(parseInt(docItem.DocStatusID)) ?  parseInt(docItem.DocStatusID) : 0 : 0;
    })
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/SetSurveyorDocs";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  SetAdditionalDetails(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/SetAdditionalDetails";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  GetClaimGarageDetail(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetClaimGarageDetail/" + reqData;
    return this.api.get(url)
  };

  GetGarageDocs(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetGarageDocs/" + reqData;
    return this.api.get(url)
  };

  // GetClaimHistory(reqData: string, encClaimID = 0): Observable<any> {
  //   const url: any = this.API_URL + "Claim/GetComments/" + reqData + "/" + encClaimID;
  //   return this.api.get(url)
  // };

  GetClaimHistory(reqData: string): Observable<any> {
    const url: any = this.API_URL + "Claim/GetComments/" + reqData;
    return this.api.get(url)
  };

  SetComments(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/SetComments";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  GetInsurerDashboardBySearch(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    reqData.ClaimID = Number(reqData.ClaimID)? reqData.ClaimID : 0;
    const url: string = this.API_URL + "Claim/GetInsurerDashboardBySearch";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  UpdateStatus(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Claim/UpdateStatus";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

  UpdateClaimInsurerDetails(reqData: any): Observable<any> {
    const headers = {"Content-Type": "application/json; charset=utf-8"}
    const url: string = this.API_URL + "Claim/UpdateClaimInsurerDetails";
    return this.api.post(url,JSON.stringify(reqData), {'headers': headers})
  }

  getVideoLink(ClaimID: any, ID: any): Observable<any> {
    const url: any = this.API_URL + "ExternalClaim/GetVideoLink/" + ClaimID + '/' + ID;
    return this.api.get(url)
  };

  getPreviousClaims(payload: any) {
    const headers = {"Content-Type": "application/json; charset=utf-8"}
    //const url: string = environment.PathClaimsAPI + 'Claim/GetClaimDetailsbyPolicy';
    const url: string = this.API_URL + 'Claim/GetClaimDetailsbyPolicy';
    return this.api.post(url,JSON.stringify(payload), {'headers': headers})
  }

  setCustomerFeedback(payload: any) {
    const headers = {"Content-Type": "application/json; charset=utf-8"}
    const url: string = environment.PathAPI + 'Claim/SetCustomerFeedback';
    return this.api.post(url,JSON.stringify(payload), {'headers': headers})
  }

  getCustomerFeedback(encClaimID: string): Observable<any> {
    //const url: any = environment.PathClaimsAPI + 'Claim/GetCustomerFeedbackReport/' + encClaimID;
    const url: any = environment.PathAPI + 'Claim/GetCustomerFeedbackReport/' + encClaimID;
    return this.api.get(url)
  };

  getCustomerFeedbackTemplate(encTxt) {
    const url: any = environment.PathAPI + "ExternalClaim/GetCustomerFeedbackTemplate/" + encTxt;
    return this.api.get(url)
  }

  sendAppreciationPost(payload) {
    const headers = {"Content-Type": "application/json; charset=utf-8"}
    const url: string = environment.PathAPI + 'ExternalClaim/ShareTemplateOnSocialMedia';
    return this.api.post(url,JSON.stringify(payload), {'headers': headers})
  }

}
