<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Gabarito:wght@600&family=Roboto:wght@700&display=swap" rel="stylesheet">
</head>
<section [ngClass]="badFeedback ? 'customerAppreciationPage' : 'customerAppreciationPage thankYou'">
    <header class="mob">
        <button class="backBtn"><span class="arrow"></span></button>
        <div class="pbLogoMob">
            <a href="#"><img  width="32" height="32" src="../../../assets/images/pb-logo-mob.png" alt="pb logo"/></a>
        </div>
    </header>
    <div [ngClass]="badFeedback ? 'leftPanel blue' : 'leftPanel green'">
        <div class="pbLogo">
            <a href="#"><img  width="195" height="51" src="../../../assets/images/pb-white-logo.png" alt="pb logo"/></a>
        </div>
        <div class="title secondaryFont">
            {{textDisplay}}
        </div>

        <div class="infographic" *ngIf="badFeedback">
            <img src="../../../assets/images/infographic.png" alt="infographic" width="350" height="360"/>
        </div>
        <div class="infographic type2" *ngIf="!badFeedback">
            <img class="left" src="../../../assets/images/thumbsUpLeft.svg"/>
            <img class="right" src="../../../assets/images/thumbsUpRight.svg"/>
        </div>


        <div class="bg2">
            <img width="314" height="311" src="./../../../assets/images/star.svg" alt="star"/>
        </div>
    </div>

    <div class="rightPanel">
        <button class="backBtn"><span class="arrow"></span></button>
        <div class="formWrapper">
        <div class="formTitle">
            {{feedbackText}}
        </div>
        <div *ngIf="badFeedback">
            <div class="formGroup" *ngFor="let reason of reasons; let i = index">
                <label class="customRadioBtn">
                    <input type="checkbox" (change)="selectReason($event, reason, i)"/>
                    <div class="radioBox">
                        <span class="square"></span>
                        <span class="text">{{reason}}</span>
                    </div>
                </label>
            </div>

            <div class="formGroup">
                <textarea cols="50" class="formControl" rows="3" placeholder="Tell us more…" [formControl]="commentControl"></textarea>
            </div>

           

            <button type="submit" [ngClass]="'primaryBtn ' + (!selectionControl.value.length ? 'disabled' : 'blue')" [disabled]="!selectionControl.value.length" (click)="initFeedbackSubmit()">Submit</button>
        </div>
        <div *ngIf="!badFeedback">
            <div class="formGroup">
                <textarea cols="50" class="formControl" rows="15" placeholder="Tell us more…" [value]="templateData?.TemplateDescription || ''" [formControl]="commentControl"></textarea>
            </div>

            <div class="socialMediaShare">
                <span class="text">Share on</span>
                <div class="socialIcons">
                    <a class="socialShareIcon" (click)="sendPost('3')">
                        <img width="32" height="32" src="../../../assets/images/twt.svg" alt="twt"/>
                    </a>
                    <a class="socialShareIcon" (click)="sendPost('1')">
                        <img width="32" height="32" src="../../../assets/images/lkdn.svg" alt="linkedin"/>
                    </a>
                </div>
                

            </div>
        </div>

     
        </div>
    </div>

</section>


